import Vue from 'vue';

import ExchangeRatesCurrencySelect from '@/components/Prices/ExchangeRatesCurrencySelect';
import { i18n, i18nSetPublicLocale } from '@/i18n';
import RatesClient from "./services/clients/rates";

import '@/plugins/bus.js';

import { getCurrentLocale } from '../assets/js/helpers/helper';
import { RatesInterval } from "../assets/js/blocks/crypto-rates-updater";

function createApp(data, listeners) {
  const app = new Vue({ // eslint-disable-line no-new
    render(h) {
      return h(ExchangeRatesCurrencySelect, {
        // Using data to make props reactive
        props: {
          items: this.items,
          loading: this.loading,
          selectedItem: this.selectedItem,
        },
        on: listeners,
      });
    },
    // Important: use original data object for keep reactivity
    data,
    i18n,
    beforeMount() {
      const locale = getCurrentLocale() || 'en';
  
      i18nSetPublicLocale(locale);
    },
  });

  return { app };
}

const RatesBlockFiatSelector = {
  init() {
    const el = document.getElementById("fiat-rates-block");
    const items = el?.dataset.currencies
      ? JSON.parse(el.dataset.currencies)
      : [];
    const initialCurrency = el?.dataset.initialCurrency || "USD";
    const data = {
      items,
      loading: false,
      selectedItem: items.find((item) => item.code === initialCurrency),
    };
    let dataLoaded = false;
    const { app } = createApp(data, {
      updated: (currencyObj) => {
        data.selectedItem = currencyObj;
        this.chooseCurrency(currencyObj.code);
      },
      dropDownOpen: () => {
        if (data.loading || dataLoaded) {
          return;
        }
        data.loading = true;
        RatesClient.getFiatCurrencyList().then(response => {
          data.items = Object.values(response);
          dataLoaded = true;
        }).finally(() => {
          data.loading = false;
        });
      },
    });
    app.$mount(el);
    
    if (data.selectedItem) {
      this.chooseCurrency(data.selectedItem.code);
    }
  },
  
  chooseCurrency(currency) {
    window.site_currency = currency;
    RatesInterval.start().then(() => {
      this.setCurrency(currency);
    });
  },
  
  setCurrency(currency) {
    window.dispatchEvent(
      new CustomEvent("currency-updated", { detail: { currency } })
    );
    
    const slider = document.getElementById("crypto-rates-block");
    slider.dispatchEvent(new CustomEvent("update-crypto-slider", { bubbles: true }));
  },
};

RatesBlockFiatSelector.init();
