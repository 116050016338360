import { ElementToggle } from "./helper";
import eventBus from "./event-bus";
import { eventNames, GlobalStorage } from "../home-page/global-storage";
import TransactionService from "../services/transaction";

class AmountQuery {
  constructor(
    from,
    to,
    amount,
    currency,
    cryptoCurrency,
    isCrypto,
    isReceived
  ) {
    this.serviceFrom = from;
    this.serviceTo = to;
    this.amount = amount || 0;
    this.isReceived = isReceived || false;
    this.isCrypto = isCrypto || false;
    this.currency = currency || "USD";
    this.cryptoCurrency = cryptoCurrency || null;
  }
}

const getFiatExchangeRate = (fiatCodeFrom, fiatCodeTo) => {
  if (fiatCodeTo === fiatCodeFrom) {
    return 1;
  }
  
  const { exchangeRates } = GlobalStorage;

  if (!(fiatCodeFrom in exchangeRates)) {
    return 0;
  }

  const rateList = exchangeRates[fiatCodeFrom];

  return parseFloat(rateList[fiatCodeTo]) || 0;
};

const convertToFiat = (amount, fiatCodeFrom, fiatCodeTo) => {
  const exchangeRate = getFiatExchangeRate(fiatCodeFrom, fiatCodeTo);
  
  if (exchangeRate === 0) {
    return amount;
  }
  
  return amount * exchangeRate;
}

const InstantExchangeBlock = {
  selectors: {
    widgetContainer: '[data-selector="iexb-container"]',
    summTo: '[data-selector="iexb-summTo"]',
    summFrom: '[data-selector="iexb-summFrom"]',
    applyExchangeAction: '[data-action="apply-exchange"]',
    block: '[data-selector="iexb-block"]',
    amountFromInput: "#exchange-form-from",
    promo: '[data-selector="iexb-promo"]',
    promoHint: '[data-selector="iexb-promo-hint"]',
  },
  currencyTo: "BTC",
  isCurrencyToCrypto: true,
  currencyFrom: null,
  isCurrencyFromCrypto: false,
  selectedFiatCurrency: "USD",
  exchangeStrategy: null, // (convertFromCrypto | convertToCrypto | convertFromCurrencyNormalized)
  isInView: false,
  isEnabled: false,
  instantExchangeAmountCurrency: "USD",
  serviceFromId: null,
  serviceToId: null,

  initEventListener() {
    if (!this.isRendered()) {
      return;
    }
    
    document.addEventListener("DOMContentLoaded", this.initLazyLoad);
    eventBus.on(eventNames.EVENT_GLOBAL_STORAGE_INITIATED, (payload) => {
      this.isEnabled = !this.getWrapperElement().classList.contains('hidden');
      this.init(payload);
    });
    eventBus.on(eventNames.EVENT_EXCHANGE_FORM_DATA_CHANGED, (payload) => {
      if (this.isInView) {
        this.isInView = false;
        this.getAllElements("summTo").forEach((element) => {
          element.innerHTML = '...';
        });
        this.initLazyLoad();
      }
      this.selectedFiatCurrency = payload.fiatCurrencyCode;
      this.init(payload);
      this.getWrapperElement().classList.add('hidden');
    });
    eventBus.on(eventNames.EVENT_RELATION_NOT_CHANGED, () => {
      if (!this.isEnabled) {
        return;
      }
      this.getWrapperElement().classList.remove('hidden');
    });
    document.body.addEventListener('page-content-changed', ({ detail }) => {
      // TODO: New exchange form need it
      const { serviceFromId, serviceToId, instantExchangeAmountList } = detail;
      this.serviceFromId = serviceFromId;
      this.serviceToId = serviceToId;
      this.setAmounts(instantExchangeAmountList);
      this.updateUI.complete();
      this.events.complete();
      this.toggleBlock();
    });

    return this;
  },

  isRendered() {
    return this.getWrapperElement() !== null;
  },

  init(payload) {
    if (!this.hasExchangeRate() && !GlobalStorage.isExtendedExchangeRatesReady) {
      // XXX: for legacy code to work, we need to load exchange rates for global storage
      GlobalStorage.leadExchangeRates().then(() => {
        this.init(payload);
      });

      return;
    }
    this.initCurrencies(payload);
    this.updateUI.complete();
    this.events.complete();
    this.toggleBlock();
  },
  
  hasExchangeRate() {
    const currencyFrom = this.instantExchangeAmountCurrency;
    const currencyTo = this.selectedFiatCurrency;

    return getFiatExchangeRate(currencyFrom, currencyTo) !== 0;
  },

  updateUI: {
    complete() {
      this.currencies();
      this.blocks();
    },

    blocks() {
      const self = InstantExchangeBlock;

      // updating summFrom
      self.getAllElements("summFrom").forEach((element) => {
        element.dataset.currency = self.currencyFrom;
        element.innerText =
          self.convertInitialAmount(element.dataset.amount) +
          " " +
          self.currencyFrom;
      });

      self.getAllElements("summTo").forEach((element) => {
        element.dataset.currency = self.currencyTo;
        self.calculateReceivedAmount(element);
      });

      // updating links
      self
        .getWrapperElement()
        .querySelectorAll("[data-initial-link]")
        .forEach((linkElement) => {
          const currentUrl = new URL(window.location.href);
          linkElement.href =
            `${currentUrl.origin}${currentUrl.pathname}` +
            "?amount=" +
            self.convertInitialAmount(linkElement.dataset.amount) +
            "&currency=" +
            self.currencyFrom;
        });
  
      // toggling blocks on/off
      self.getAllElements("block").forEach((block) => {
        const amount = block.querySelectorAll("[data-amount]").item(0).dataset
          .amount;
        return self.convertInitialAmount(amount)
          ? ElementToggle.show(block)
          : ElementToggle.hide(block);
      });
    },

    currencies() {
      const self = InstantExchangeBlock;
      const currencyDirection =
        self.exchangeStrategy === "convertFromCrypto"
          ? "currencyTo"
          : "currencyFrom";
      self.selectedFiatCurrency = self[currencyDirection];
    },
  },

  events: {
    complete() {
      this.currencies();
      this.blocks();
    },

    currencies() {
      const self = InstantExchangeBlock;
      if (
        self.selectedFiatCurrency === self.currencyFrom ||
        self.selectedFiatCurrency === self.currencyTo
      ) {
        return;
      }
      self.getAllElements("summFrom").forEach((element) => {
        // setting selected crypto currency
        element.classList.add(self.getCurrencyIconClass(self.currencyFrom));
        // updating summFrom
        const { amount, currency } = element.dataset;
        element.innerText = self.convertInitialAmount(amount + " " + currency);
      });
      self.getAllElements("summTo").forEach((element) => {
        // setting selected crypto currency
        element.classList.add(self.getCurrencyIconClass(self.currencyTo));
      });

      // updating links
      self
        .getWrapperElement()
        .querySelectorAll("[data-initial-link]")
        .forEach((element) => {
          const currentUrl = new URL(window.location.href);
          element.href =
            `${currentUrl.origin}${currentUrl.pathname}` +
            "?amount=" +
            self.convertInitialAmount(element.dataset.amount) +
            "&currency=" +
            self.currencyFrom;
        });
    },

    blocks() {
      const self = InstantExchangeBlock;

      self.getAllElements("block").forEach((block) => {
        block.addEventListener("click", (event) => {
          event.stopPropagation();
          event.preventDefault();

          const amountFromInput = self.getOneElement("amountFromInput");
          amountFromInput.value = self.convertInitialAmount(
            block.querySelector("[data-amount]").dataset.amount
          );
          amountFromInput.dispatchEvent(new Event("change"));
        });
      });

      self.getAllElements("applyExchangeAction").forEach((link) => {
        link.addEventListener("click", (event) => {
          event.stopPropagation();
          event.preventDefault();

          const blocks = self.getAllElements("block");
          blocks.forEach((block) => {
            if (block.contains(link)) {
              return block.classList.add("selected");
            }
            block.classList.remove("selected");
          });

          history.replaceState(null, null, link.href);

          const amountFromInput = self.getOneElement("amountFromInput");

          if (amountFromInput) {
            
            // updating dependent service
            amountFromInput.value = self.convertInitialAmount(
              link.dataset.amount
            );
            amountFromInput.dispatchEvent(new Event("change"));
            
          }

          document.body.dispatchEvent(new CustomEvent('exchange-form__from-amount', {
            detail: self.convertInitialAmount(
                link.dataset.amount
            ),
          }));

          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        });
      });
    },
  },

  setAmounts(amounts) {
    this.isEnabled = false;
    if (typeof amounts === "object" && amounts && amounts.length === 4) {
      this.isEnabled = true;
      this.getAllElements("block").forEach((element, index) => {
        element.querySelectorAll("[data-amount]").forEach(amountOwner => {
          amountOwner.dataset.amount = amounts[index] || 0;
        });
      });
    }
    return this;
  },

  initCurrencies({ isServiceFromCrypto, isServiceToCrypto, cryptoCurrencyCode, fiatCurrencyCode, serviceFromId, serviceToId }) {
    this.currencyFrom = isServiceFromCrypto ? cryptoCurrencyCode : fiatCurrencyCode;
    this.currencyTo = isServiceToCrypto ? cryptoCurrencyCode : fiatCurrencyCode;
    this.isCurrencyFromCrypto = isServiceFromCrypto;
    this.isCurrencyToCrypto = isServiceToCrypto;
    this.serviceFromId ||= serviceFromId;
    this.serviceToId ||= serviceToId;

    // impossible to convert crypto -> crypto
    if (isServiceFromCrypto && isServiceToCrypto) {
      return this;
    }

    this.exchangeStrategy =
      isServiceFromCrypto && !isServiceToCrypto
        ? "convertFromCrypto" // crypto -> online
        : !isServiceFromCrypto && isServiceToCrypto
        ? "convertToCrypto" // online -> crypto
        : !isServiceFromCrypto && !isServiceToCrypto
        ? "convertFromCurrencyNormalized"
        : null; // online -> online

    return this;
  },
  
  initLazyLoad() {
    const self = InstantExchangeBlock;
    const lazyElements = self.getAllElements("summTo");

    if ("IntersectionObserver" in window) {
      const lazyElementObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting === false) {
            return;
          }
          const lazyElement = entry.target;
          self.isInView = true;
          self.calculateReceivedAmount(lazyElement);
          lazyElementObserver.unobserve(lazyElement);
        });
      });
    
      lazyElements.forEach((lazyElement) => {
        lazyElementObserver.observe(lazyElement);
      });
    } else {
      // Fallback
      self.isInView = true;
      lazyElements.forEach((lazyElement) => {
        self.calculateReceivedAmount(lazyElement);
      });
    }
  },

  calculateReceivedAmount(element) {
    if (!this.isInView) {
      return;
    }
    let amount = element.dataset.amount > 0 ? element.dataset.amount : 0;

    if (!this.isEnabled) {
      return;
    }
    if (amount > 0) {
      amount = this.convertInitialAmount(amount);
    }
    const query = new AmountQuery(
      this.serviceFromId,
      this.serviceToId,
      amount,
      this.selectedFiatCurrency,
      this.isCurrencyFromCrypto ? this.currencyFrom : this.currencyTo,
      this.isCurrencyFromCrypto,
      false
    );
 
    TransactionService.getQuote(query).then((response) => {
      this.setCalculatedAmount(response, element);
    })
  },

  setCalculatedAmount({ buy }, element) {
    const { fiat, crypto } = buy;
    let amount = 0;
  
    if (this.exchangeStrategy === "convertFromCrypto") {
      amount = fiat.amount;
      amount =
        amount >= 10000 ? parseInt(amount) : parseFloat(amount).toFixed(2);
    } else if (this.exchangeStrategy === "convertFromCurrencyNormalized") {
      amount = fiat.amount;
      amount = amount.toFixed(2);
    } else {
      // round NEO amount
      if (crypto && crypto.currency === "NEO") {
        amount = parseInt(crypto.amount);
      } else {
        amount = crypto.amount;
        amount =
          amount >= 10000
            ? parseInt(amount)
            : parseFloat(amount).toFixed(8).substring(0, 6);
      }
    }
  
    element.innerText = amount + " " + element.dataset.currency;
  },

  toggleBlock() {
    this.getWrapperElement().classList.toggle("hidden", !this.isEnabled);
    this.resetButtons();
  },

  convertInitialAmount(amount) {
    if (
      this.exchangeStrategy === "convertFromCrypto" ||
      this.selectedFiatCurrency === this.instantExchangeAmountCurrency
    ) {
      return amount;
    }

    try {
      const convertedAmount = convertToFiat(amount, this.instantExchangeAmountCurrency, this.selectedFiatCurrency);
      const amountString = parseInt(convertedAmount).toString();
  
      if (!amountString || amountString === "NaN" || amountString.length <= 2) {
        return amount;
      }
  
      return amountString.substring(0, 2) + "0".repeat(amountString.length - 2);
    } catch (e) {
      if (e.message === 'Exchange rates not ready') {
      
      }
    }
    
    return amount;
  },

  resetButtons() {
    this.getWrapperElement()
      .querySelectorAll("[data-initial-link]")
      .forEach((element) => {
        ElementToggle.show(element);
      });
  },

  getCurrencyIconClass(currency) {
    currency = currency.toLowerCase();
    // @TODO: remove CURRENCY_ICON_CLASS
    if (
      typeof CURRENCY_ICON_CLASS === "object" &&
      typeof CURRENCY_ICON_CLASS[currency] === "string"
    ) {
      return CURRENCY_ICON_CLASS[currency];
    }
    return "fas fa-" + currency;
  },

  getWrapperElement() {
    return this.getOneElement("widgetContainer");
  },

  getOneElement(selector) {
    return document.querySelector(this.selectors[selector]);
  },

  getAllElements(selector) {
    return document.querySelectorAll(this.selectors[selector]);
  },
};

InstantExchangeBlock.initEventListener();
